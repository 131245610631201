@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap');
@import '~antd/dist/antd.less';
@import './variables.less';

h1{
    font-size: @heading-1-size;
    font-weight: @heading-1-weight;
    color: @heading-1-color;
    line-height: @heading-1-line-height;
}

h2{
    font-size: @heading-2-size;
    font-weight: @heading-2-weight;
    color: @heading-2-color;
    line-height: @heading-2-line-height;
}

p{
    color: @paragraph-color;
    line-height: @paragraph-line-height;
}

.mx-auto{
    margin-left: auto;
    margin-right: auto;
}

// Ant customizations
// Do it if there has no ant customization variable available.

.ant-form-item-label > label{
    font-weight: 600;
}

.ant-input:focus, .ant-input-focused {
    box-shadow: none;
}
.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector {
    box-shadow: none;
    border-right-width: 0px !important;
}
.ant-select {
    width: 100%;
}
.border {
    margin: 16px 0;
    padding: 0;
    width: calc(100% + 64px);
    margin-left: -32px;
    border: 0 solid #E0E0E0;
    border-bottom: 1px solid #E0E0E0;
}
.bottom-fixed {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    background: #fff;
    padding: 0 32px 17px 32px;
}
.pb-40 {
    padding-bottom: 40px;
}

.policy-text {
    font-size: 12px;
    line-height: 18px;
    color: #0F212E;
    a {
        text-decoration: underline;
    }
}
.mb-16{
    margin-bottom: 16px;
}
.mt-16 {
    margin-top: 16px;
}
.mb-0{
    margin-bottom: 0;
}
.mx-16{
    margin-top: 16px;
    margin-bottom: 16px;
}
.text-warning{
    color: #f57c00;
}
@primary-color: #008AC8;@font-family: 'Poppins',-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol','Noto Color Emoji';@btn-font-weight: 500;@layout-body-background: #fff;@height-base: 40px;@height-lg: 48px;@height-sm: 32px;@border-radius-base: 0;@text-color: #0F212E;@padding-sm: 16px;@disabled-bg: #CDD3D8;@disabled-color: #EBF2F7;@form-item-margin-bottom: 16px;@form-item-label-font-size: 12px;@form-item-label-height: 18px;@label-color: #6A7581;